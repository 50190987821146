import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  modalDetail: {
    paddingBottom: '20px',
    alignContent: 'center',
    justifyContent: 'space-around',
    overflow: 'hidden'
  },
  btnContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  mappedHeading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
  },
  selectMapped: { width: 425 },
  modalRoot: {
    '& div.MuiDialog-paper': {
      background: theme.palette.background.default,
      width: '630px',
    },
  },
  fullFlex: {
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(0),
    flexBasis: '100%',
  },
  detailList: {
    height: '180px',
    overflow: 'auto'
  },
  cardcontainer: {
    marginBottom: '8px',
  },
  cardbox: {
    padding: '10px !important',
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  subSection: {
    display: 'flex',
    alignItems: 'center',
  },
  pic: {
    marginRight: '6px',
  },
  groupText: {
    fontWeight: 600,
    lineHeight: 'normal',
  },
  fullName: {
    position: 'relative',
    left: '30px',
  },
  close: {
    minWidth: 30,
    padding: '1px 5px',
    marginLeft: '5px',
  },
}));