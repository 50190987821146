import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import { VIEW_MODE } from '@wings/shared';
import {
  CustomAgGridReact,
  AgGridGroupHeader,
  AgGridActionButton,
  AgGridChipViewStatus,
  useGridState,
  useAgGrid,
} from '@wings-shared/custom-ag-grid';
import { useStyles } from './Users.styles';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { Theme, Button } from '@material-ui/core';
import { finalize } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { IAPIPagedUserRequest, UserModel, UserStore } from '../Shared';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { ColDef, GridOptions, GridReadyEvent } from 'ag-grid-community';
import { LOGS_FILTERS } from '../Shared/Enums';
import { FilterIcon, ExportUserIcon } from '@uvgo-shared/icons';
import { ExportUsers } from '../Core/Components';
import FilterUsers from './Components/FilterUsers/FilterUsers';
import { CustomLinkButton } from '@wings-shared/layout';
import {
  GridPagination,
  IAPIGridRequest,
  IClasses,
  ISelectOption,
  UIStore,
  Utilities,
  SearchStore,
  GRID_ACTIONS,
  cellStyle,
} from '@wings-shared/core';
import { ISearchHeaderRef, SearchHeaderV2 } from '@wings-shared/form-controls';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useLocation } from 'react-router-dom';
import { usePermissionsV2 } from '@wings-shared/security';

type Props = {
  classes?: IClasses;
  theme?: Theme;
  userStore?: UserStore;
};

const Users: FC<Props> = ({ ...props }: Props) => {
  const unsubscribe = useUnsubscribe();
  const gridState = useGridState();
  const location = useLocation();
  const agGrid = useAgGrid<LOGS_FILTERS, UserModel>([], gridState);
  const searchHeaderRef = useRef<ISearchHeaderRef>();
  const classes = useStyles();
  const [ permissions ] = usePermissionsV2([ 'write' ]);
  const [ users, setUserList ] = useState<UserModel[]>([]);

  useEffect(() => {
    const searchData = SearchStore.searchData.get(location.pathname);
    if (searchData?.searchValue) {
      gridState.setPagination(searchData.pagination);
      searchHeaderRef.current?.setupDefaultFilters(searchData);
      SearchStore.clearSearchData(location.pathname);
      return;
    }
    loadInitialData();
  }, []);

  useEffect(() => {
    if (props.userStore?.updatedUserData) {
      if (users.length) {
        const updatedUser = users.some(x => x.id == props.userStore?.updatedUserData.id);
        if (updatedUser) {
          const updatedUserIndex = users.findIndex(x => x.id == props.userStore?.updatedUserData.id);
          users[updatedUserIndex] = props.userStore?.updatedUserData;
          setUserList(users);
          gridState.setGridData(users);
        }
      }
    }
  }, [ props.userStore?.updatedUserData ])

  const loadInitialData = (pageRequest?: IAPIGridRequest): void => {
    const _searchValue = searchHeaderRef.current?.searchValue;
    const request: IAPIGridRequest = {
      ...pageRequest,
      q: _searchValue,
      sort: 'name',
      status: props.userStore?.userFilter,
      provider: props.userStore?.providerFilter,
    };
    UIStore.setPageLoader(true);
    props.userStore
      ?.getUsers(request)
      .pipe(finalize(() => UIStore.setPageLoader(false)))
      .subscribe(response => {
        gridState.setPagination(new GridPagination({ ...response }));
        gridState.setGridData(response.results);
        setUserList(response.results);
      });
  };

  const columnDefs: ColDef[] = [
    {
      headerName: 'First Name',
      field: 'firstName',
      comparator: (current: ISelectOption, next: ISelectOption) => Utilities.customComparator(current, next, 'value'),
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      comparator: (current: ISelectOption, next: ISelectOption) => Utilities.customComparator(current, next, 'value'),
    },
    {
      headerName: 'Okta Username',
      field: 'username',
      comparator: (current: ISelectOption, next: ISelectOption) => Utilities.customComparator(current, next, 'value'),
    },
    {
      headerName: 'CSD Username',
      field: 'csdUsername',
    },
    {
      headerName: 'CustomerNumber',
      field: 'customerNumber',
      cellRenderer: 'agGridChipViewStatus',
      cellRendererParams: {
        isPlainText: true,
      },
      cellClass : 'customerChip'
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 200,
      cellRenderer: 'agGridChipViewStatus',
      cellRendererParams: {
        isPlainText: true,
        isString: true,
      },
    },
    {
      headerName: 'Oracle Username',
      field: 'oracleFNDUsername',
    },
    {
      headerName: 'Action',
      cellRenderer: 'actionButtonRenderer',
      suppressSizeToFit: true,
      suppressNavigable: true,
      cellStyle: { ...cellStyle() },
      cellRendererParams: {
        isHidden: () => false,
        to: node => `/user-management/users/${node.data?.id}/${VIEW_MODE.EDIT.toLowerCase()}`,
        edit: true,
        isDisabled: () => !permissions.hasAnyPermission,
        onAction: () => {
          if (searchHeaderRef.current) {
            SearchStore.saveSearchData(location.pathname, {
              ...searchHeaderRef.current.getFilters(),
              pagination: gridState.pagination,
            });
          }
        },
      },
    },
  ];

  const gridOptions = (): GridOptions => {
    return {
      ...agGrid.gridOptionsBase({
        context: this,
        columnDefs,
        isEditable: false,
        gridActionProps: {
          showDeleteButton: false,
          getDisabledState: () => gridState.hasError,
          onAction: (action: GRID_ACTIONS, rowIndex: number) => {},
        },
      }),
      isExternalFilterPresent: () => false,
      frameworkComponents: {
        agGridChipViewStatus: AgGridChipViewStatus,
        customHeader: AgGridGroupHeader,
        actionButtonRenderer: AgGridActionButton,
      },
      onGridReady: (event: GridReadyEvent) => {
        event.api.setDatasource({ getRows: () => loadInitialData() });
        gridState.setGridApi(event.api);
        gridState.setColumnApi(event.columnApi);
      },
    };
  }

  const exportUsers = (): void =>{
    const searchRequest = {
      searchCollection: JSON.stringify([
        { propertyName: 'username', propertyValue: searchHeaderRef.current?.searchValue },
      ]),
    };
    ModalStore.open(
      <ExportUsers userStore={props.userStore} request={searchRequest} classes={props.classes} />
    );
  }

  const filterUsers = (): void => {
    ModalStore.open(
      <FilterUsers
        onSetClick={({ provider, status }) => {
          loadInitialData();
          ModalStore.close();
        }}
      />
    );
  }

  return (
    <>
      <div className={classes.userListContainer}>
        <div className={classes.headerContainer}>
          <SearchHeaderV2
            ref={searchHeaderRef as RefObject<ISearchHeaderRef>}
            selectInputs={[]}
            hasSelectInputsValues={false}
            onFilterChange={isInitEvent =>
              loadInitialData({ pageNumber: isInitEvent ? gridState.pagination.pageNumber : 1 })
            }
            onExpandCollapse={agGrid.autoSizeColumns}
          />
          <Button
            variant="contained"
            color="primary"
            className={classes.filterBtn}
            onClick={() => filterUsers()}
            startIcon={<FilterIcon />}
          ></Button>
          <PrimaryButton
            variant="contained"
            color="primary"
            disabled={gridState.isProcessing}
            className={classes.filterBtn}
            onClick={() => exportUsers()}
            startIcon={<ExportUserIcon />}
          ></PrimaryButton>
          <span className={classes.newBtn}>
            <CustomLinkButton
              variant="contained"
              to={VIEW_MODE.NEW.toLowerCase()}
              title="Create New User"
              disabled={!permissions.hasAnyPermission}
            />
          </span>
        </div>
        <div className={classes.mainroot}>
          <div className={classes.mainContent}>
            <CustomAgGridReact
              gridOptions={gridOptions()}
              rowData={gridState.data}
              serverPagination={true}
              paginationData={gridState.pagination}
              customRowsPerPageLabel="Page Size"
              onPaginationChange={loadInitialData}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default inject('userStore')(observer(Users));
