import { TextField } from '@material-ui/core';
import { Dialog } from '@uvgo-shared/dialog';
import { inject, observer } from 'mobx-react';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import React, { ChangeEvent, FC, ReactNode, useState } from 'react';
import { PrimaryButton } from '@uvgo-shared/buttons';
import { IAPIPagedUserRequest, UserStore } from '../../../Shared';
import { finalize } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import { useStyles } from './ExportUsers.style';
import { AxiosError } from 'axios';
import { UIStore, regex, IClasses } from '@wings-shared/core';

type Props = {
  userStore: UserStore;
  request?: IAPIPagedUserRequest;
  classes?: IClasses;
};

const ExportUsers: FC<Props> = ({ ...props }: Props) => {
  const [ inputValue, setInputValue ] = useState<string>('');
  const classes = useStyles();

  const exportUsers = (): void => {
    UIStore.setPageLoader(true);
    props.userStore
      .exportUsers(inputValue, props.request)
      .pipe(
        finalize(() => {
          UIStore.setPageLoader(false);
          ModalStore.close();
        })
      )
      .subscribe(
        () => AlertStore.info('Users exported successfully!'),
        (error: AxiosError) => AlertStore.critical(error.message));
  }

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const { value } = event.target;
    setInputValue(value);
  }

  const isValidValue = (): boolean => {
    return regex.email.test(inputValue);
  }

  const dialogContent = (): ReactNode => {
    return (
      <div className={classes.modalDetail}>
        <div className={classes.rowContainer}>
          <TextField
            className={classes.fullFlex}
            onChange={event => onChange(event)}
            required={true}
            placeholder='Enter the email where you want to export the data'
          />
        </div>
        {
          !isValidValue() && <div className={classes.errorText}>
            Please enter valid Email
          </div>
        }
        <div className={classes.btnContainer}>
          <div className={classes.bluText} onClick={() => { ModalStore.close() }}>
            Cancel
          </div>
          <PrimaryButton
            variant='contained'
            color='primary'
            onClick={() => exportUsers()}
            disabled={!isValidValue()}
          >
            Send
          </PrimaryButton>
        </div>
      </div>
    );
  }

  return (
    <Dialog
      title='Export Users'
      open={true}
      classes={{
        dialogWrapper: classes.modalRoot,
        paperSize: classes.userMappedWidth,
        header: classes.headerWrapper,
        content: classes.content,
      }}
      onClose={() => ModalStore.close()}
      dialogContent={() => dialogContent()}
    />
  )
}

export default inject('userStore')(observer(ExportUsers));