import React, { FC, ReactNode, useState } from 'react';
import { VIEW_MODE } from '@wings/shared';
import { inject, observer } from 'mobx-react';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { Typography, Card, CardContent, Button } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import { IAPIPagedUserRequest, UserResponseModel, UserStore } from '../../../Shared';
import { useStyles } from './AssignedUsers.styles';
import { filter, finalize, takeUntil } from 'rxjs/operators';
import { IClasses, UIStore, Utilities, ViewPermission } from '@wings-shared/core';
import { useUnsubscribe } from '@wings-shared/hooks';
import { SearchInputControl } from '@wings-shared/form-controls';

type Props = {
  viewMode?: VIEW_MODE;
  classes?: IClasses;
  userStore?: UserStore;
  onAssignUser: (userId: string) => void;
};

const AssignedUsers: FC<Props> = ({ ...props }: Props) => {
  const unsubscribe = useUnsubscribe();
  const classes = useStyles();
  const [ users, setUsers ] = useState<UserResponseModel[]>([]);
  const [ searchValue, setSearchValue ] = useState<string>('');

  const loadUsers = (key: string): void => {
    if (!(Utilities.isEqual(key.toLowerCase(), 'enter') && searchValue.length > 1)) {
      return;
    }
    const request: IAPIPagedUserRequest = {
      searchCollection: JSON.stringify([{ PropertyName: 'username', propertyValue: searchValue },
        { PropertyName: 'status', PropertyValue: 'ACTIVE', Operator: 'eq' }])  
    };
    UIStore.setPageLoader(true);
    props.userStore?.loadUsers(request)
      .pipe(
        takeUntil(unsubscribe.destroy$),
        filter(response => Boolean(response.results)),
        finalize(() => UIStore.setPageLoader(false))
      )
      .subscribe(({ results }) => {
        setUsers(results);
      });
  }

  const dialogContent = (): ReactNode => {
    return (
      <>
        <div className={classes.modalDetail}>
          <div className={classes.mappedHeading}>
            <Typography variant="h6" component="h2" className={classes.modalHeading}>
              Search Users
            </Typography>
            <div className={classes.selectMapped}>
              <SearchInputControl
                onKeyUp={(key: string) => loadUsers(key)}
                onSearch={(searchValue: string) => setSearchValue(searchValue) }
                placeHolder="Search Users"
              />
            </div>
          </div>
          <div>
            <ViewPermission hasPermission={Boolean(users.length)}>
              <div className={classes.detailList}>
                {
                  users.map((u, i) =>
                    <Card key={i} variant="outlined" className={classes.cardcontainer}>
                      <CardContent className={classes.cardbox}>
                        <div>
                          <div className={classes.subSection}>
                            <PersonIcon className={classes.pic} />
                            <Typography variant="subtitle1" className={classes.groupText}>{u.username}</Typography>
                          </div>
                          <Typography
                            variant="caption"
                            className={classes.fullName}>
                            {u.fullName}
                          </Typography>
                        </div>
                        <div>
                          <Button
                            onClick={() => {
                              ModalStore.close();
                              props.onAssignUser(u.oktaUserId)
                            }}
                            className={classes.close} variant="outlined" size="small">
                            Add
                          </Button>
                        </div>
                      </CardContent>
                    </Card>
                  )
                }
              </div>
            </ViewPermission>
          </div>
        </div>
      </>
    );
  }

  return (
    <Dialog
      title="Assign User to Group"
      open={true}
      classes={{
        dialogWrapper: classes.modalRoot,
      }}
      onClose={() => ModalStore.close()}
      dialogContent={() => dialogContent()}
    />
  )
}

export default inject('userStore')(observer(AssignedUsers));