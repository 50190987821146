import React, { FC, ReactNode, useEffect } from 'react';
import { action } from 'mobx';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { useStyles } from './SessionsModal.styles';
import { withStyles, Theme, Button } from '@material-ui/core';
import { finalize } from 'rxjs/operators';
import { inject, observer } from 'mobx-react';
import { ColDef, GridOptions, RowNode, ValueFormatterParams } from 'ag-grid-community';
import { Dialog } from '@uvgo-shared/dialog';
import { PrimaryButton } from '@uvgo-shared/buttons';
import moment from 'moment';
import PeopleIcon from '@material-ui/icons/People';
import { SessionStore, UserModel, UserSessionModel } from '../../../Shared';
import { DATE_FORMAT, IClasses, UIStore, Utilities, IBaseGridFilterSetup } from '@wings-shared/core';
import { LOGS_FILTERS, SESSION_FILTER } from '../../../Shared/Enums';
import { SearchHeader } from '@wings-shared/form-controls';
import {
  AgGridViewRenderer,
  CustomAgGridReact,
  BaseGrid,
  AgGridActions,
  AgGridGroupHeader,
  useGridState,
  useAgGrid,
} from '@wings-shared/custom-ag-grid';
import { useUnsubscribe } from '@wings-shared/hooks';
import { useParams } from 'react-router';
import { VIEW_MODE, useBaseUpsertComponent } from '@wings/shared';

type Props = {
  classes?: IClasses;
  theme?: Theme;
  sessionStore?: SessionStore;
  user?: UserModel;
  openSessionDeleteConfirmation?: (session: UserSessionModel, user: UserModel) => void;
};

const SessionsModal: FC<Props> = ({ ...props }) => {
  const classes = useStyles();
  const unsubscribe = useUnsubscribe();
  const gridState = useGridState();
  const params = useParams();
  const useUpsert = useBaseUpsertComponent(params, null);
  const agGrid = useAgGrid<LOGS_FILTERS, UserSessionModel>([], gridState);

  useEffect(() => {
    useUpsert.setViewMode((params?.mode?.toUpperCase() as VIEW_MODE) || VIEW_MODE.NEW);
    loadInitialData();
  }, []);

  const loadInitialData = () => {
    const { user, sessionStore } = props;
    sessionStore
      .loadSessionUsers(user.oktaUserId)
      .pipe(finalize(() => UIStore.setPageLoader(false)))
      .subscribe((data: UserSessionModel[]) => {
        gridState.setGridData(data);
      });
  }

  const columnDefs: ColDef[] = [
    {
      headerName: 'Connection Id',
      field: 'connectionId',
    },
    {
      headerName: 'Client Name',
      field: 'clientName',
    },
    {
      headerName: 'Client Id',
      field: 'clientId',
    },
    {
      headerName: 'Connection Timestamp',
      field: 'timestamp',
      valueFormatter: ({ value }: ValueFormatterParams) =>
        Utilities.getformattedDate(moment.utc(value).format(DATE_FORMAT.API_FORMAT), DATE_FORMAT.SDT_DST_FORMAT),
    },
    {
      headerName: 'IP Address',
      field: 'ipAddress',
    },
    {
      headerName: 'User Agent',
      field: 'userAgent',
    },
    {
      headerName: 'Action',
      field: 'action',
      cellRenderer: 'viewRenderer',
      filter: false,
      minWidth: 100,
      cellRendererParams: {
        getViewRenderer: (rowIndex: number, node: RowNode) => viewRenderer(node.data),
      },
    },
  ];

  const viewRenderer = (rowData: UserSessionModel): ReactNode => {
    return (
      <PrimaryButton
        variant="contained"
        color="primary"
        onClick={() => props.openSessionDeleteConfirmation(rowData, props.user)}
      >
        Delete
      </PrimaryButton>
    );
  }

  const gridOptions = (): GridOptions => {
    return {
      ...agGrid.gridOptionsBase({
        context: this,
        columnDefs,
        isEditable: true,
        gridActionProps: {
          showDeleteButton: false,
          getDisabledState: () => gridState.hasError,
          onAction: (action: GRID_ACTIONS, rowIndex: number) => {},
        },
      }),
      isExternalFilterPresent: () => searchHeaderRef.current?.hasSearchValue || false,
      doesExternalFilterPass: node => {
        const searchHeader = searchHeaderRef.current;
        if (!searchHeader) {
          return false;
        }
        const { clientName, ipAddress } = node.data as UserSessionModel;
        return agGrid.isFilterPass(
          {
            [SESSION_FILTER.ALL]: [ clientName.toString(), ipAddress.toString() ],
            [SESSION_FILTER.CLIENTNAME]: clientName.toString(),
            [SESSION_FILTER.IPADDRESS]: ipAddress.toString(),
          },
          searchHeader.searchValue,
          searchHeader.selectedOption
        );
      },
    };
  }

  const dialogContent = (): ReactNode => {
    const { user } = props;
    return (
      <>
        <div className={classes.sessionContainer}>
          <div className={classes.mainroot}>
            <CustomAgGridReact gridOptions={gridOptions()} rowData={this.data} />
          </div>
        </div>
        <Button
          color="primary"
          variant="contained"
          size="small"
          className={classes.btnAlign}
          onClick={() => ModalStore.close()}
        >
          Close
        </Button>
      </>
    );
  }

  return (
    <Dialog
      title={`Sessions : ${props.user.username}`}
      open={true}
      classes={{
        dialogWrapper: classes.modalRoot,
        paperSize: classes.paperSize,
        header: classes.headerWrapper,
        content: classes.content,
      }}
      onClose={() => ModalStore.close()}
      dialogContent={() => dialogContent()}
    />
  );
}

export default inject('sessionStore')(observer(SessionsModal));
